import React, { useState } from "react";
import { read, readFile, utils, writeFile } from "xlsx";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import {
  addAnomalyIdentifiers,
  addILIMeasurementValue,
  addILIMeasurementError80,
  addFieldMeasurementValue,
  addFieldMeasurementError80,
  addFullDataJSON,
  addFullDataArray,
  addLevelTwo,
  addLevelThree,
} from "../../Reducers/toleranceSlice";
import axios from "axios";
import { AnalyticEvent } from "../../CommonFunctions";

const ExcelButton = () => {
  const columnsMain = useSelector((state) => state.toleranceData.columns);
  const [columns, setColumns] = useState([columnsMain]);
  const [video, setVideo] = useState(false);

  const dispatch = useDispatch();

  const [fileName, setFileName] = useState(null);

  const downloadFile = () => {
    AnalyticEvent("API1163App", "API1163App_DownloadFile");
    var wb = utils.book_new();
    var ws = utils.aoa_to_sheet(columns);
    utils.book_append_sheet(wb, ws, "API 1163 Level 3 Analysis");

    writeFile(wb, "API1163Analysis.xlsx");
  };

  const handleFile = async (e) => {
    AnalyticEvent("API1163App", "API1163App_ViewResults");
    const file = e.target.files[0];

    setFileName(file.name);

    const data = await file.arrayBuffer();
    const workbook = read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
      skipHeader: true,
      range: 1,
    });

    var anomalyIdentifier = jsonData.map((value) => value[0]);
    var iliMeasurement = jsonData.map((value) => value[1]);
    var iliMeasurementError = jsonData.map((value) => value[2]);
    var fieldMeasurement = jsonData.map((value) => value[3]);
    var fieldMeasurementError = jsonData.map((value) => value[4]);

    dispatch(addAnomalyIdentifiers(anomalyIdentifier));
    dispatch(addILIMeasurementValue(iliMeasurement));
    dispatch(addILIMeasurementError80(iliMeasurementError));
    dispatch(addFieldMeasurementValue(fieldMeasurement));
    dispatch(addFieldMeasurementError80(fieldMeasurementError));

    dispatch(addFullDataArray(jsonData));

    const pureJsonData = utils.sheet_to_json(worksheet);

    dispatch(addFullDataJSON(pureJsonData));

    const levelTwoResults = await axios.post(
      `${process.env.REACT_APP_API_URL}/API1163Level2`,
      {
        iliMeasurement,
        iliMeasurementError,
        fieldMeasurement,
        fieldMeasurementError,
      }
    );

    dispatch(addLevelTwo(levelTwoResults.data));

    const levelThreeResults = await axios.post(
      `${process.env.REACT_APP_API_URL}/API1163Level3`,
      {
        iliMeasurement,
        fieldMeasurement,
      }
    );

    dispatch(addLevelThree(levelThreeResults.data));
  };

  return (
    <div>
      <h1 className="text">
        Inline Inspection Measurement Tool (API 1163 Level 2 and 3 Assessment){" "}
      </h1>
      <h2 className="text">
        Calculate the true tolerance bounds on your corrosion and crack
        anomalies
      </h2>
      <p>
        You can {video ? "close" : "open"} our instructional demo{" "}
        <a
          onClick={() => {
            setVideo(!video);
          }}
        >
          here.{" "}
        </a>
        You can also{" "}
        <a href="https://infermodel.com/#contact" target="_blank">
          {" "}
          contact us{" "}
        </a>{" "}
        for assistance using our tools.
      </p>
      <p>
        You can find the template used in our video with a glossary of terms{" "}
        <a
          href="https://docs.google.com/spreadsheets/d/1r1_xm91Z6-qn1K6NJdo2ewjl_-hpCCJ1/edit?usp=sharing&ouid=102634183221380086574&rtpof=true&sd=true"
          target="_blank"
        >
          here
        </a>
      </p>
      {video && (
        <div>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/wQ_-qZUpjFE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      )}
      <Button variant="contained" onClick={downloadFile}>
        {" "}
        Download Template Sheet{" "}
      </Button>
      {/* {fileName && (
        <React.Fragment>
          <p>
            File Name:<span>{fileName}</span>
          </p>
          <p>
            Columns:{" "}
            <select>
              {" "}
              {columns.map((c) => (
                <option value={c}>{c}</option>
              ))}
            </select>
          </p>
        </React.Fragment>
      )} */}

      <Button
        variant="outlined"
        component="label"
        style={{ margin: " 2em 2em" }}
      >
        Upload File
        <input type="file" hidden onChange={(e) => handleFile(e)} />
      </Button>
    </div>
  );
};

export default ExcelButton;
