import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";
import CrackExcelButton from "./CrackExcelButton";
import CrackTable from "./CrackTable";
import Disclaimer from "../CommonComponents/Disclaimer";
import { AnalyticEvent } from "../../CommonFunctions";
import Navbar from "../CommonComponents/Navbar";

function CorlasApp() {
  const [showTable, setShowTable] = useState(false);

  const resultsExist = useSelector((state) =>
    Object.keys(state.crackData.crackResults).length == 0 ? false : true
  );

  const viewTable = () => {
    AnalyticEvent("CrackApp", "CrackApp_ViewResults");
    setShowTable(true);
  };

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Crack Failure Pressure</title>
        <link rel="canonical" href="infermodel.com" />
      </Helmet>
      <Navbar />
      <CrackExcelButton />
      <h2 style={{ textAlign: "center", display: "inline-block" }}>
        Find the Failure Pressure of an External Pipe Crack :
      </h2>
      <span style={{ width: "1em", display: "inline-block" }}></span>
      <Button variant="contained" onClick={viewTable} disabled={!resultsExist}>
        {" "}
        View Your Results
      </Button>

      {resultsExist && showTable && <CrackTable />}
      {showTable && <Disclaimer />}
    </div>
  );
}

export default CorlasApp;
