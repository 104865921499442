import * as React from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";

export default function CrackTable() {
  const columns = useSelector((state) => state.crackData.finalColumns);

  const rows = useSelector((state) => state.crackData.crackResults);

  var result = Object.entries(rows).reduce(
    (r, [key, array]) => array.map((v, i) => ({ ...r[i], [key]: v })),
    []
  );

  const dispatch = useDispatch();

  const columnsTwo = [
    { field: `${columns[0]}`, headerName: `id`, width: 20 },
    { field: `${columns[1]}`, headerName: `${columns[1]}`, width: 300 },
    { field: `${columns[2]}`, headerName: `${columns[2]}`, width: 350 },
    { field: `${columns[3]}`, headerName: `${columns[3]}`, width: 300 },
    { field: `${columns[4]}`, headerName: `${columns[4]}`, width: 350 },
    { field: `${columns[5]}`, headerName: `${columns[5]}`, width: 350 },
    { field: `${columns[6]}`, headerName: `${columns[6]}`, width: 350 },
    { field: `${columns[7]}`, headerName: `${columns[7]}`, width: 350 },
    { field: `${columns[8]}`, headerName: `${columns[8]}`, width: 350 },
    { field: `${columns[9]}`, headerName: `${columns[9]}`, width: 350 },
    { field: `${columns[10]}`, headerName: `${columns[10]}`, width: 350 },
    { field: `${columns[11]}`, headerName: `${columns[11]}`, width: 350 },
    { field: `${columns[12]}`, headerName: `${columns[12]}`, width: 350 },
    { field: `${columns[13]}`, headerName: `${columns[13]}`, width: 350 },
    { field: `${columns[14]}`, headerName: `${columns[14]}`, width: 350 },
    { field: `${columns[15]}`, headerName: `${columns[15]}`, width: 350 },
    { field: `${columns[16]}`, headerName: `${columns[16]}`, width: 350 },
    { field: `${columns[17]}`, headerName: `${columns[17]}`, width: 350 },
    { field: `${columns[18]}`, headerName: `${columns[18]}`, width: 350 },
  ];

  return (
    <div style={{ height: 400, width: "90%", margin: "auto" }}>
      <DataGrid
        rows={result}
        columns={columnsTwo}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        className="Table"
        components={{ Toolbar: GridToolbar }}
      />
    </div>
  );
}
