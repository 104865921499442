import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  columns: [
    "id",
    "Pipe Name",
    "Odometer (ft)",
    "Begin Chainage (ft)",
    "End Chainage (ft)",
    "Specified Minimum Yield Strength (psi)",
    "Specified Minimum Tensile Strength (psi)",
    "Charpy V - Notch Energy (lb-ft)",
    "Charpy Impact Area (in2))",
    "Modulus of Elasticity (psi)",
    "Wall Thickness (inch)",
    "Outside Diameter (inch)",
    "Operating Pressure (psig)",
    "Flaw Length (in)",
    "Flaw Depth (in)",
  ],
  finalColumns: [
    "id",
    "Pipe Name",
    "Odometer (ft)",
    "Begin Chainage (ft)",
    "End Chainage (ft)",
    "Specified Minimum Yield Strength (psi)",
    "Specified Minimum Tensile Strength (psi)",
    "Charpy V - Notch Energy (lb-ft)",
    "Charpy Impact Area (in2)",
    "Modulus of Elasticity (psi)",
    "Wall Thickness (inch)",
    "Outside Diameter (inch)",
    "Operating Pressure (psig)",
    "Flaw Length (in)",
    "Flaw Depth (in)",
    "Ductile Failure Pressure (psig)",
    "Brittle Failure Pressure (psig)",
    "Final Failure Pressure (psig)",
    "Conclusion on Operating Pressure",
  ],
  id: [],
  pipeName: [],
  odometer: [],
  beginChain: [],
  endChain: [],
  yieldStrength: [],
  tensileStrength: [],
  charpyEnergy: [],
  charpyArea: [],
  modulusElasticity: [],
  wallThickness: [],
  outsideDiameter: [],
  operatingPressure: [],
  flawLength: [],
  flawDepth: [],
  crackResults: {},
};

export const crackSlice = createSlice({
  name: "crackSlice",
  initialState,
  reducers: {
    addAnomalyIdentifiers: (state, action) => {
      state.id = [...action.payload];
    },
    addPipeName: (state, action) => {
      state.pipeName = [...action.payload];
    },
    addOdometer: (state, action) => {
      state.Odometer = [...action.payload];
    },
    addBeginChain: (state, action) => {
      state.beginChain = [...action.payload];
    },
    addEndChain: (state, action) => {
      state.endChain = [...action.payload];
    },
    addYieldStrength: (state, action) => {
      state.yieldStrength = [...action.payload];
    },
    addTensileStrength: (state, action) => {
      state.tensileStrength = [...action.payload];
    },
    addCharpyEnergy: (state, action) => {
      state.charpyEnergy = [...action.payload];
    },
    addCharpyArea: (state, action) => {
      state.charpyArea = [...action.payload];
    },
    addModulusElasticity: (state, action) => {
      state.modulusElasticity = [...action.payload];
    },
    addWallThickness: (state, action) => {
      state.wallThickness = [...action.payload];
    },
    addOutsideDiameter: (state, action) => {
      state.outsideDiameter = [...action.payload];
    },
    addOperatingPressure: (state, action) => {
      state.operatingPressure = [...action.payload];
    },
    addFlawLength: (state, action) => {
      state.flawLength = [...action.payload];
    },
    addFlawDepth: (state, action) => {
      state.flawDepth = [...action.payload];
    },
    addCrackResults: (state, action) => {
      state.crackResults = action.payload;
    },
  },
});

export const {
  addAnomalyIdentifiers,
  addPipeName,
  addOdometer,
  addBeginChain,
  addEndChain,
  addYieldStrength,
  addTensileStrength,
  addCharpyEnergy,
  addCharpyArea,
  addModulusElasticity,
  addWallThickness,
  addOutsideDiameter,
  addOperatingPressure,
  addFlawLength,
  addFlawDepth,
  addCrackResults,
} = crackSlice.actions;
export default crackSlice.reducer;
