import * as React from "react";

import { Chart } from "react-google-charts";

import { useSelector, useDispatch } from "react-redux";

export const options = {
  hAxis: {
    title: "ILI Measurement (%WT)",
    minValue: 0,
    maxValue: 100,
    textStyle: { top: "0%" },
  },
  vAxis: {
    title: "Field Measurement (%WT)",
    minValue: 0,
    maxValue: 100,
    textStyle: { marginTop: "10" },
  },
  seriesType: "scatter",
  series: {
    1: { type: "line", lineWidth: 3 },
    2: { type: "line", lineWidth: 3, color: "green" },
    3: { type: "line", lineWidth: 3, color: "green" },
  },
  legend: "none",
  chartArea: { top: "2%", height: "450" },
};

const ChartResults = () => {
  const dataTwo = useSelector((state) => state.toleranceData.fullDataJSON);
  const columnsMain = useSelector((state) => state.toleranceData.columns);

  const Error = useSelector(
    (state) => state.toleranceData.ILIMeasurementError80
  );

  const averageError = Error.reduce((a, b) => a + b, 0) / Error.length || 0;

  const finalDateNow = dataTwo.map(
    ({ [`${columnsMain[1]}`]: testOne, [`${columnsMain[3]}`]: testTwo }) => [
      testOne,
      testTwo,
      testOne,
      testOne - averageError < 0 ? 0 : testOne - averageError,
      testOne + averageError > 100 ? 100 : testOne + averageError,
    ]
  );

  const finalData = finalDateNow.length > 0 ? finalDateNow : [0, 0, 0, 0, 0];

  // const plotData = dataTwo.map(
  //   ({
  //     [`${columnsMain[0]}`]: testOne,
  //     [`${columnsMain[2]}`]: testTwo,
  //     ...keepAttrs
  //   }) => keepAttrs
  // );

  return (
    <div>
      <Chart
        chartType="ComboChart"
        height="500px"
        data={[
          [
            `${columnsMain[1]}`,
            `${columnsMain[3]}`,
            "Unity Plot",
            "Lower Bound",
            "Upper Bound",
          ],
          ...finalData,
        ]}
        options={options}
      />
    </div>
  );
};

export default ChartResults;
