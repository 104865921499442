import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  columns: [
    "Feature id",
    "Pipe Name",
    "Inspection Name",
    "Inspection Date (month day year)",
    "Odometer (ft)",
    "Wheel Count Start (ft)",
    "Wheel Count End (ft)",
    "O Clock Start",
    "O Clock End",
    "Anomaly Flaw Length (in)",
    "Anomaly Flaw Width (in)",
    "Anomaly Flaw Depth (in)",
    "Depth Growth Rate (mils per year)",
    "Length Growth Rate (mils per year)",
    "Width Growth Rate (mils per year)",
    "Distance to nearest Girth Weld (ft)",
    "Wall Thickness (in)",
    "Outside Diameter (in)",
    "Operating Pressure (psig)",
    "Specified Minimum Yield Strength (psi)",
    "Specified Minimum Tensile Strength (psi)",
  ],
  finalColumns: [
    "id",
    "Feature Id Contained",
    "Pipe Name",
    "Inspection Name",
    "Inspection Date (month day year)",
    "Wheel Count Start (ft)",
    "Wheel Count End (ft)",
    // "Wheel Count Start (ft)",
    // "Wheel Count End (ft)",
    // "O Clock Start",
    // "O Clock End",
    // "Flaw Length (in)",
    // "Flaw Width (in)",
    // "Flaw Depth (in)",
    // "Depth Growth Rate (mils per year)",
    // "Length Growth Rate (mils per year)",
    // "Width Growth Rate (mils per year)",
    //"Distance to nearest Girth Weld (ft)",
    "Wall Thickness (in)",
    "Outside Diameter (in)",
    "Operating Pressure (psig)",
    "Specified Minimum Yield Strength (psi)",
    "Specified Minimum Tensile Strength (psi)",
    "Projection Time",
    "Cluster Max Length (in)",
    // "Cluster Max Width (in)",
    "Cluster Max Depth (in)",
    "Cluster Effective Length (in)",
    "Cluster Effective Depth (in)",
    "Distance of Closest Callbox to Girth Weld (in)",
    // "Failure Pressure at Detection Time (psi)",
    // "FPR at Detection Time",
    "Failure Pressure at Projection Time (psi)",
    "Failure Pressure Ratio (FPR) at Projection Time",
    // "Remaining Life of Defect",
    // "Suggested Reinspection Interval of Pipe",
    "Judgement at Projection Time",
  ],
  corrosionResults: {},
  simulationStart: false,
};

export const corrosionSlice = createSlice({
  name: "corrosionSlice",
  initialState,
  reducers: {
    addCorrosionResults: (state, action) => {
      state.corrosionResults = action.payload;
    },
    addSimulationStart: (state, action) => {
      state.simulationStart = action.payload;
    },
  },
});

export const { addCorrosionResults, addSimulationStart } =
  corrosionSlice.actions;
export default corrosionSlice.reducer;
