import React, { useState } from "react";
import { read, readFile, utils, writeFile } from "xlsx";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import {
  addAnomalyIdentifiers,
  addPipeName,
  addOdometer,
  addBeginChain,
  addEndChain,
  addYieldStrength,
  addTensileStrength,
  addCharpyEnergy,
  addCharpyArea,
  addModulusElasticity,
  addWallThickness,
  addOutsideDiameter,
  addOperatingPressure,
  addFlawLength,
  addFlawDepth,
  addCrackResults,
} from "../../Reducers/crackSlice";
import axios from "axios";
import { AnalyticEvent } from "../../CommonFunctions";

const CrackExcelButton = () => {
  const columnsMain = useSelector((state) => state.crackData.columns);
  const [columns, setColumns] = useState([columnsMain]);
  const [video, setVideo] = useState(false);
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState(null);

  const downloadFile = () => {
    AnalyticEvent("CrackApp", "CrackApp_DownloadFile");
    var wb = utils.book_new();
    var ws = utils.aoa_to_sheet(columns);
    utils.book_append_sheet(wb, ws, "Crack Failure Pressure");

    writeFile(wb, "CrackFailurePressure.xlsx");
  };

  const handleFile = async (e) => {
    const file = e.target.files[0];

    setFileName(file.name);

    const data = await file.arrayBuffer();
    const workbook = read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
      skipHeader: true,
      range: 1,
    });

    var anomalyIdentifiers = jsonData.map((value) => value[0]);
    var pipeName = jsonData.map((value) => value[1]);
    var odometer = jsonData.map((value) => value[2]);
    var beginChain = jsonData.map((value) => value[3]);
    var endChain = jsonData.map((value) => value[4]);
    var yieldStrength = jsonData.map((value) => value[5]);
    var tensileStrength = jsonData.map((value) => value[6]);
    var charpyEnergy = jsonData.map((value) => value[7]);
    var charpyArea = jsonData.map((value) => value[8]);
    var modulusElasticity = jsonData.map((value) => value[9]);
    var wallThickness = jsonData.map((value) => value[10]);
    var outsideDiameter = jsonData.map((value) => value[11]);
    var operatingPressure = jsonData.map((value) => value[12]);
    var flawLength = jsonData.map((value) => value[13]);
    var flawDepth = jsonData.map((value) => value[14]);

    dispatch(addAnomalyIdentifiers(anomalyIdentifiers));
    dispatch(addPipeName(pipeName));
    dispatch(addOdometer(odometer));
    dispatch(addBeginChain(beginChain));
    dispatch(addEndChain(endChain));
    dispatch(addYieldStrength(yieldStrength));
    dispatch(addTensileStrength(tensileStrength));
    dispatch(addCharpyEnergy(charpyEnergy));
    dispatch(addCharpyArea(charpyArea));
    dispatch(addModulusElasticity(modulusElasticity));
    dispatch(addWallThickness(wallThickness));
    dispatch(addOutsideDiameter(outsideDiameter));
    dispatch(addOperatingPressure(operatingPressure));
    dispatch(addFlawLength(flawLength));
    dispatch(addFlawDepth(flawDepth));

    const crackResults = await axios.post(
      `${process.env.REACT_APP_API_URL}/CRACKMODEL`,
      {
        anomalyIdentifiers,
        pipeName,
        odometer,
        beginChain,
        endChain,
        yieldStrength,
        tensileStrength,
        charpyEnergy,
        charpyArea,
        modulusElasticity,
        wallThickness,
        outsideDiameter,
        operatingPressure,
        flawLength,
        flawDepth,
      }
    );

    dispatch(addCrackResults(crackResults.data));
  };

  return (
    <div>
      <h1 className="text">Deterministic Failure Pressure of Cracks </h1>
      <h2 className="text">
        Calculate the failure pressure of your crack like anomalies assuming a
        semi-eliptical flaw shape and 10 ksi flow stress rule
      </h2>
      <p>
        You can {video ? "close" : "open"} our instructional demo{" "}
        <a
          onClick={() => {
            setVideo(!video);
          }}
        >
          here.{" "}
        </a>
        You can also{" "}
        <a href="https://infermodel.com/#contact" target="_blank">
          {" "}
          contact us{" "}
        </a>{" "}
        for assistance using our tools.
      </p>
      <p>
        You can find the template used in our video with a glossary of terms{" "}
        <a
          href="https://docs.google.com/spreadsheets/d/1s-JEYaNnHp_RKJ9kQ9sU3kfu2LHAi5Zo/edit?usp=sharing&ouid=102634183221380086574&rtpof=true&sd=true"
          target="_blank"
        >
          here
        </a>
      </p>
      {video && (
        <div>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/NIAbXXWEYQA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      )}

      <Button variant="contained" onClick={downloadFile}>
        {" "}
        Download Template Sheet{" "}
      </Button>

      <Button
        variant="outlined"
        component="label"
        style={{ margin: " 2em 2em" }}
      >
        Upload File
        <input type="file" hidden onChange={(e) => handleFile(e)} />
      </Button>
    </div>
  );
};

export default CrackExcelButton;
