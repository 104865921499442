import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Button, FlatButton } from "@mui/material";
import { AnalyticEvent } from "../../CommonFunctions";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { read, readFile, utils, writeFile } from "xlsx";
import CorrosionExcelButton from "./CorrosionExcelButton";
import {
  addCorrosionResults,
  addSimulationStart,
} from "../../Reducers/corrosionClusteringSlice";
import axios from "axios";

function CorrosionForm() {
  const dispatch = useDispatch();
  const [runTool, setRunTool] = useState(false);
  const [showTable, setShowTable] = useState(false);

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  async function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    try {
      dispatch(addSimulationStart(true));
      const corrosionResults = await axios.post(
        `${process.env.REACT_APP_API_URL}/CORROSIONCLUSTERING`,
        formData
      );
      dispatch(addSimulationStart(false));

      dispatch(addCorrosionResults(corrosionResults.data));
    } catch (err) {
      console.log(err);
      alert("An error occured, please check your input file and try again");
      window.location.reload();
    }
  }

  return (
    <Grid item xs={12}>
      <hr />
      <form method="post" onSubmit={handleSubmit}>
        <Grid container spacing={1.5}>
          <Grid item xs={4}>
            <label>
              <b> Corrosion Burst Model: </b>
              <select
                name="Corrosion Model"
                defaultValue="RSTRENG Level 2 Assessment"
              >
                <option value="ASME B31G">ASME B31G</option>
                <option value="ASME B31G Modified">ASME B31G Modified</option>
                <option value="RSTRENG Level 2 Assessment">
                  RSTRENG Level 2 Assessment
                </option>
              </select>
            </label>
          </Grid>

          <Grid item xs={3}>
            <label>
              <b> Flow Stress Model: </b>
              <select name="Flow Stress Model" defaultValue="one">
                <option value="one">SMYS + 10 ksi</option>
                <option value="two">1.1 x SMYS</option>
                <option value="three">(SMYS+SMTS)/2</option>
              </select>
            </label>
          </Grid>

          <Grid item xs={5}>
            <label>
              <b> Individual Callbox Type: </b>
              <select name="Dimension Type" defaultValue="Total">
                <option value="Total">Peak Dimensions</option>
                <option value="Effective">Effective Dimensions</option>
              </select>
            </label>
          </Grid>

          <Grid item xs={12}>
            <hr />
            <p>
              <b>Clustering Interaction Rule</b>
            </p>
          </Grid>
          <Grid item xs={6}>
            <p>
              <b>Axial Wall Thickness:</b>{" "}
              <input name="AxialInteraction" defaultValue={6} type="number" />{" "}
            </p>
          </Grid>
          <Grid item xs={6}>
            <p>
              <b> Circumferential Wall Thickness:</b>{" "}
              <input
                name="CircumferentialInteraction"
                defaultValue={6}
                type="number"
              />{" "}
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <b>Remove Features From Clustering With Depths Below (% WT):</b>{" "}
              <input name="depthFilter" defaultValue={10} type="number" />{" "}
            </p>
          </Grid>
          <Grid item xs={12}>
            <hr />
            <p>
              <b>Grow Corrosion to what date?</b>:{" "}
              <input name="date" type="date" defaultValue={date} />
            </p>
          </Grid>
          <Grid item xs={4}>
            <b>Minimum Depth Growth Rate (mils/year):</b>{" "}
            <input name="depthCGR" defaultValue={4.0} type="number" />{" "}
          </Grid>
          <Grid item xs={4}>
            <b>Minimum Length Growth Rate (mils/year): </b>
            <input name="lengthCGR" defaultValue={0.0} type="number" />{" "}
          </Grid>
          <Grid item xs={4}>
            <b>Minimum Width Growth Rate (mils/year): </b>
            <input name="widthCGR" defaultValue={0.0} type="number" />{" "}
          </Grid>

          {/* <Grid item xs={12}>
            <hr />
            <p>
              <b> Remaining Life and Reinspection Interval Assessment: </b>
              <input type="checkbox" name="myCheckbox" defaultChecked={true} />
            </p>
          </Grid> */}
          <Grid item xs={6}>
            <Button
              variant="outlined"
              component="label"
              style={{ margin: " 2em 2em" }}
            >
              Upload File
              <input
                type="file"
                name="file"
                hidden
                onChange={(e) => {
                  setRunTool(true);
                }}
              />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              component="label"
              style={{ margin: " 2em 2em" }}
              type="submit"
              label="Submit"
              disabled={!runTool}
            >
              <button type="submit" label="Submit" disabled={!runTool} hidden />
              Run Corrosion Assessment
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default CorrosionForm;
