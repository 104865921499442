import React from "react";
import Grid from "@mui/material/Grid";

export default function Disclaimer() {
  return (
    <Grid
      container
      spacing={2}
      style={{
        width: "100%",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Grid item xs={12}>
        <h3>
          Interested in a faster and more detailed assessment that accounts for
          uncertainty, reach out to us at info@infermodel.com
        </h3>
      </Grid>

      <Grid item xs={12}>
        <p style={{ fontSize: "0.8em" }}>
          Legal Disclaimer: All content and information on this website
          including our programs, products and/or services is for informational
          and educational purposes only, does not constitute professional advice
          of any kind and does not establish any kind of professional-client
          relationship by your use of this website. A professional-client
          relationship with you is only formed after we have expressly entered
          into a written agreement. inferModel excludes all warranties including
          that the software is error-free, that it is the right tool for a
          particular purpose, and that the data uploaded is protected.
        </p>
      </Grid>
    </Grid>
  );
}
