import { Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Table from "./Table";
import ChartResults from "./Chart";
import ExcelButton from "./ExcelButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { HashLink as Link } from "react-router-hash-link";
import { useSelector, useDispatch } from "react-redux";
import Results from "./Results";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import Navbar from "../CommonComponents/Navbar";

function ValidationApp() {
  const [showTable, setShowTable] = useState(true);

  const resultsExist = useSelector((state) =>
    Object.keys(state.toleranceData.levelTwo).length == 0 ? false : true
  );

  const handleChange = (event) => {
    setShowTable(event.target.value);
  };

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inline Inspection Validation</title>
        <link rel="canonical" href="infermodel.com" />
      </Helmet>
      <Navbar />
      <ExcelButton />
      <h2 style={{ textAlign: "center", display: "inline-block" }}>
        View All Your Inspection Data as a :
      </h2>
      <span style={{ width: "1em", display: "inline-block" }}></span>
      <FormControl className="formsize">
        <InputLabel id="demo-simple-select-label">Options</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Options"
          onChange={handleChange}
        >
          <MenuItem value={true}>Table</MenuItem>
          <MenuItem value={false}>Graph</MenuItem>
        </Select>
      </FormControl>
      {resultsExist && (
        <h2
          style={{
            textAlign: "center",
            "margin-left": "5em",
            display: "inline-block",
          }}
        >
          <Link to="#api1163results">See your results</Link>
        </h2>
      )}

      {showTable ? <Table /> : <ChartResults />}

      {resultsExist && (
        <span id="api1163results">
          <Results />{" "}
        </span>
      )}
    </div>
  );
}

export default ValidationApp;
