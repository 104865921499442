import { configureStore } from "@reduxjs/toolkit";
import toleranceSlice from "./Reducers/toleranceSlice";
import crackSlice from "./Reducers/crackSlice";
import corrosionClusteringSlice from "./Reducers/corrosionClusteringSlice";

export const store = configureStore({
  reducer: {
    toleranceData: toleranceSlice,
    crackData: crackSlice,
    corrosionClusteringData: corrosionClusteringSlice,
  },
});
