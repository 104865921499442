import Grid from "@mui/material/Grid";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Chart } from "react-google-charts";
import Disclaimer from "../CommonComponents/Disclaimer";

export const optionsChart = {
  hAxis: {
    title: "ILI Measurement (%WT)",
    minValue: 0,
    maxValue: 100,
    textStyle: { top: "0%" },
  },
  vAxis: {
    title: "Field Measurement (%WT)",
    minValue: 0,
    maxValue: 100,
    textStyle: { top: "-100%" },
  },
  seriesType: "scatter",
  series: {
    1: { type: "line", lineWidth: 3 },
    2: { type: "line", lineWidth: 3, color: "green" },
    3: { type: "line", lineWidth: 3, color: "green" },
  },
  legend: "none",
  chartArea: { top: "2%", bottom: "10%" },
  height: 350,
};

export default function Results() {
  const levelTwoPInBound = useSelector(
    (state) => state.toleranceData.levelTwo.pInBound
  );

  const levelTwoPEst = useSelector(
    (state) => state.toleranceData.levelTwo.pEst
  );

  const levelTwoPLower = useSelector(
    (state) => state.toleranceData.levelTwo.pLower
  );

  const levelTwoPUpper = useSelector(
    (state) => state.toleranceData.levelTwo.pUpper
  );

  const levelTwoSituation = useSelector(
    (state) => state.toleranceData.levelTwo.situation
  );

  const levelThreeErrorMean = useSelector(
    (state) => state.toleranceData.levelThree.errorMean
  );

  const levelThreeErrorStd = useSelector(
    (state) => state.toleranceData.levelThree.errorStdWithinErrors
  );

  const levelThreeActualLowerInterval = useSelector(
    (state) => state.toleranceData.levelThree.actualLowerInterval
  );

  const levelThreeActualUpperInterval = useSelector(
    (state) => state.toleranceData.levelThree.actualUpperInterval
  );

  const dataTwo = useSelector((state) => state.toleranceData.fullDataJSON);
  const columnsMain = useSelector((state) => state.toleranceData.columns);

  const finalDateNow = dataTwo.map(
    ({ [`${columnsMain[1]}`]: testOne, [`${columnsMain[3]}`]: testTwo }) => [
      testOne,
      testTwo,
      testOne,
      testOne - levelThreeActualLowerInterval < 0
        ? 0
        : testOne + levelThreeActualLowerInterval,
      testOne + levelThreeActualUpperInterval > 100
        ? 100
        : testOne + levelThreeActualUpperInterval,
    ]
  );

  const finalData = finalDateNow.length > 0 ? finalDateNow : [0, 0, 0, 0, 0];

  const downloadFile = () => {};

  const checkColorLevelTwo = (description) => {
    switch (description) {
      case "Confidently Better":
        return "green";
      case "Statistically Possible":
        return "orange";
      case "Confidently Worse":
        return "red";
      default:
    }
  };

  const checkColorLevelThree = (value) => {
    if (value >= 0) {
      return "green";
    } else {
      return "red";
    }
  };

  const checkSituationLevelThree = (value) => {
    if (value >= 0) {
      return "Over-Sizing";
    } else {
      return "Under-Sizing";
    }
  };

  {
    const levelTwoSeries = [
      {
        name: "Proportion of Measurements in Spec",
        data: [
          {
            x: "Your Data",
            y: Math.round(levelTwoPInBound * 100),
            goals: [
              {
                name: "lowerBound",
                value: Math.round(levelTwoPLower * 100),
                strokeHeight: 5,
                strokeColor: "blue",
              },
              {
                name: "upperBound",
                value: Math.round(levelTwoPUpper * 100),
                strokeHeight: 5,
                strokeColor: "blue",
              },
              {
                name: "Vendor Claimed Confidence",
                value: 80,
                strokeHeight: 5,
                strokeColor: "red",
              },
            ],
          },
        ],
      },
    ];

    const options = {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      colors: ["#00E396"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: [
          "Mean Proportion of Measurements within Spec",
          "Lower Bound Hypothesis Test at 80% Confidence",
          "Upper Bound Hypothesis Test at 80% Confidence",
          "Claimed Tool Specification",
        ],
        markers: {
          fillColors: ["#00E396", "blue", "blue", "red"],
        },
      },
      yaxis: {
        title: {
          text: "(%) Measurements within Spec ",
        },
        min: 0,
        max: 100,
      },
      fill: {
        opacity: 0.5,
      },
    };

    return (
      <div>
        <Grid
          container
          spacing={2}
          style={{
            height: 400,
            width: "100%",
            margin: "auto",
            "padding-top": "2em",
            textAlign: "center",
          }}
        >
          <Grid item xs={6}>
            <h2>Level 2 Assessment</h2>
            <h3>Is the vendor tool performance possible?</h3>
            <p>
              Our analysis shows that the vendor specs are{" "}
              <span
                style={{
                  "font-weight": "bold",
                  color: `${checkColorLevelTwo(levelTwoSituation)}`,
                }}
              >
                {levelTwoSituation}
              </span>{" "}
              than specified
            </p>
            <p>
              <b>{levelTwoPInBound * 100}%</b> of measurements are within
              specification
            </p>
            <p>
              The 80% Confidence Interval shows a lower bound at{" "}
              {Math.round(levelTwoPLower * 100, 0)} % and upper bound at{" "}
              {Math.round(levelTwoPUpper * 100, 0)} %{" "}
            </p>
            <ReactApexChart
              options={options}
              series={levelTwoSeries}
              type="bar"
              height={350}
            />
          </Grid>
          <Grid item xs={6}>
            <h2>Level 3 Assessment</h2>
            <h3>What is the tool performace?</h3>

            <p>
              Our analysis shows that the vendor tool is{" "}
              <span
                style={{
                  "font-weight": "bold",
                  color: `${checkColorLevelThree(levelThreeErrorMean)}`,
                }}
              >
                {checkSituationLevelThree(levelThreeErrorMean)}{" "}
              </span>
            </p>
            {levelThreeErrorMean < 0 ? (
              <p>
                The Average Under-Sizing Error is{" "}
                <b>{Math.round(levelThreeErrorMean, 1)} % WT</b> with a scatter
                of <b>{Math.round(levelThreeErrorStd, 1)}% WT</b>{" "}
              </p>
            ) : (
              <p>
                The ILI Over-Sizes on average with a bias of{" "}
                <b>{Math.round(levelThreeErrorMean, 1)} % WT</b> and scatter of{" "}
                <b>{Math.round(levelThreeErrorStd, 1)} % WT</b>{" "}
              </p>
            )}
            <p>
              New tolerance bound:
              <b>
                {" "}
                {Math.round(levelThreeActualLowerInterval, 1)} % WT and +
                {Math.round(levelThreeActualUpperInterval, 1)} % WT at 80%
                Confidence
              </b>
            </p>
            <Chart
              chartType="ComboChart"
              data={[
                [
                  `${columnsMain[1]}`,
                  `${columnsMain[3]}`,
                  "Unity Plot",
                  "Lower Bound",
                  "Upper Bound",
                ],
                ...finalData,
              ]}
              options={optionsChart}
            />
          </Grid>
          <Grid item xs={6}>
            <p>
              {" "}
              This analysis was performed using the <b>Agresti-Coull Method </b>
            </p>
          </Grid>
          <Grid item xs={6}>
            <p>
              This analysis was performed using the <b>Howe-Guenther Method</b>
            </p>
          </Grid>

          <Grid item xs={12}>
            <Disclaimer />
          </Grid>
        </Grid>
      </div>
    );
  }
}
