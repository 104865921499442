import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  columns: [
    "id",
    "ILI Measurement Value (%WT)",
    "ILI Measurement Error (%WT) at 80% Confidence",
    "Field Measurement (%WT)",
    "Field Measurement Error (%WT) at 80% Confidence",
  ],
  id: [],
  ILIMeasurementValue: [],
  ILIMeasurementError80: [],
  FieldMeasurementValue: [],
  FieldMeasurementError80: [],
  fullDataJSON: [],
  fullDataArray: [],
  levelTwo: {},
  levelThree: {},
};

export const toleranceSlice = createSlice({
  name: "toleranceSlice",
  initialState,
  reducers: {
    addAnomalyIdentifiers: (state, action) => {
      state.id = [...action.payload];
    },
    addILIMeasurementValue: (state, action) => {
      state.ILIMeasurementValue = [...action.payload];
    },
    addILIMeasurementError80: (state, action) => {
      state.ILIMeasurementError80 = [...action.payload];
    },
    addFieldMeasurementValue: (state, action) => {
      state.FieldMeasurementValue = [...action.payload];
    },
    addFieldMeasurementError80: (state, action) => {
      state.FieldMeasurementValue = [...action.payload];
    },
    addFullDataJSON: (state, action) => {
      state.fullDataJSON = [...action.payload];
    },
    addFullDataArray: (state, action) => {
      state.fullDataArray = [...action.payload];
    },
    addLevelTwo: (state, action) => {
      state.levelTwo = action.payload;
    },
    addLevelThree: (state, action) => {
      state.levelThree = action.payload;
    },
  },
});

export const {
  addAnomalyIdentifiers,
  addILIMeasurementValue,
  addILIMeasurementError80,
  addFieldMeasurementValue,
  addFieldMeasurementError80,
  addFullDataJSON,
  addFullDataArray,
  addLevelTwo,
  addLevelThree,
} = toleranceSlice.actions;
export default toleranceSlice.reducer;
