import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";

export default function Table() {
  const columns = useSelector((state) => state.toleranceData.columns);

  const rows = useSelector((state) => state.toleranceData.fullDataJSON);

  //   const rows = [
  //   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  //   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  //   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  //   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  //   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  //   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  //   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  //   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  //   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  // ];
  const dispatch = useDispatch();

  const columnsTwo = [
    { field: `${columns[0]}`, headerName: `id`, width: 20 },
    { field: `${columns[1]}`, headerName: `${columns[1]}`, width: 300 },
    { field: `${columns[2]}`, headerName: `${columns[2]}`, width: 350 },
    { field: `${columns[3]}`, headerName: `${columns[3]}`, width: 300 },
    { field: `${columns[4]}`, headerName: `${columns[4]}`, width: 350 },
  ];

  return (
    <div style={{ height: 400, width: "80%", margin: "auto" }}>
      <DataGrid
        rows={rows}
        columns={columnsTwo}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        className="Table"
      />
    </div>
  );
}
