import React, { useState } from "react";
import { read, readFile, utils, writeFile } from "xlsx";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import {
  addAnomalyIdentifiers,
  addPipeName,
  addOdometer,
  addBeginChain,
  addEndChain,
  addYieldStrength,
  addTensileStrength,
  addCharpyEnergy,
  addCharpyArea,
  addModulusElasticity,
  addWallThickness,
  addOutsideDiameter,
  addOperatingPressure,
  addFlawLength,
  addFlawDepth,
  addCrackResults,
} from "../../Reducers/crackSlice";
import axios from "axios";
import { AnalyticEvent } from "../../CommonFunctions";
import Grid from "@mui/material/Grid";

function CorrosionExcelButton() {
  const columnsMain = useSelector(
    (state) => state.corrosionClusteringData.columns
  );
  const [columns, setColumns] = useState([columnsMain]);
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState(null);
  const [video, setVideo] = useState(false);

  const downloadFile = () => {
    AnalyticEvent("ClusteringApp", "ClusteringApp_DownloadFile");
    var wb = utils.book_new();
    var ws = utils.aoa_to_sheet(columns);
    utils.book_append_sheet(wb, ws, "Corrosion Integrity Assessment");

    writeFile(wb, "CorrosionIntegrityAssessment.xlsx");
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        style={{
          width: "100%",
          margin: "auto",
        }}
      >
        <Grid item xs={12}>
          <h1 className="text">
            Deterministic Level 2 Corrosion Assessment and Clustering Tool
          </h1>
          <h2 style={{ textAlign: "center", display: "inline-block" }}>
            Grow and Cluster Your Corrosion Anomalies for Burst Pressure
            Assessments.
          </h2>
        </Grid>

        <Grid item xs={12}>
          <p>
            You can {video ? "close" : "open"} our instructional demo{" "}
            <a
              onClick={() => {
                setVideo(!video);
              }}
            >
              here.{" "}
            </a>
            You can also{" "}
            <a href="https://infermodel.com/#contact" target="_blank">
              {" "}
              contact us{" "}
            </a>{" "}
            for assistance using our tools.
          </p>
          <p>
            You can find the template used in our video with a glossary of terms{" "}
            <a
              href="https://docs.google.com/spreadsheets/d/13ZsXTIXhxqmvJLLqXyc_RoClGewti7FB/edit?usp=sharing&ouid=102634183221380086574&rtpof=true&sd=true"
              target="_blank"
            >
              here
            </a>
          </p>
          {video && (
            <div>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/60Bj3jBrFdU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          )}

          <p>
            Our RSTRENG Simulation finds the weakest point of your corrosion
            cluster with a grid search precision of 0.05 inches up to 1000
            pieces.
          </p>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            style={{
              margin: " 2em 0em",
              textAlign: "center",
            }}
            onClick={downloadFile}
          >
            {" "}
            Download Inline Inspection Template Sheet{" "}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default CorrosionExcelButton;
