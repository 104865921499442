import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import ValidationApp from "./Components/API1163Tool/ValidationApp";
import CorlasApp from "./Components/CorlasTool/CorlasApp";
import ClusteringApp from "./Components/CorrosionClusteringTool/CorrosionClusteringApp";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-YX9K0K2DXV"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [showTable, setShowTable] = useState(true);

  const resultsExist = useSelector((state) =>
    Object.keys(state.toleranceData.levelTwo).length == 0 ? false : true
  );

  const handleChange = (event) => {
    setShowTable(event.target.value);
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <Routes>
      <Route path="/InlineInspectionValidation" element={<ValidationApp />} />
      <Route path="/CrackFailurePressure" element={<CorlasApp />} />
      <Route path="/CorrosionClustering" element={<ClusteringApp />} />
    </Routes>
  );
}

export default App;
