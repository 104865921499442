import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="box">
      <img
        src={require("../../Logo.png")}
        alt="logo"
        style={{
          width: "5em",
          height: "5em",
          marginRight: "2em",
          marginLeft: "1em",
        }}
      />
      <bold>
        <a href="https://www.infermodel.com" color="blue">
          Home Website
        </a>
      </bold>
    </div>
  );
}
