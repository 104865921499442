import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Button } from "@mui/material";
import { AnalyticEvent } from "../../CommonFunctions";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { read, readFile, utils, writeFile } from "xlsx";
import CorrosionExcelButton from "./CorrosionExcelButton";
import CorrosionForm from "./CorrosionForm";
import CorrosionTable from "./CorrosionTable";
import Disclaimer from "../CommonComponents/Disclaimer";
import Navbar from "../CommonComponents/Navbar";
import ClipLoader from "react-spinners/ClipLoader";

function CorrosionClusteringApp() {
  const resultsExist = useSelector((state) =>
    Object.keys(state.corrosionClusteringData.corrosionResults).length == 0
      ? false
      : true
  );

  const simulationStart = useSelector(
    (state) => state.corrosionClusteringData.simulationStart
  );

  const [showTable, setShowTable] = useState(false);

  const viewTable = () => {
    AnalyticEvent("ClusteringApp", "ClusteringApp_ViewResults");
    setShowTable(true);
  };

  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Grow and Cluster Your Corrosion Anomalies for Burst Pressure
          Assessments
        </title>
        <link rel="canonical" href="infermodel.com" />
      </Helmet>
      <Grid
        container
        spacing={2}
        style={{
          width: "100%",
          margin: "auto",
        }}
      >
        <Navbar />

        <CorrosionExcelButton />
        <CorrosionForm />
      </Grid>

      <span style={{ width: "1em", display: "inline-block" }}></span>
      <ClipLoader
        color={"blue"}
        loading={simulationStart}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <Button
        variant="contained"
        onClick={viewTable}
        disabled={!resultsExist}
        style={{ marginBottom: "3em" }}
      >
        {" "}
        View Your Results
      </Button>

      {resultsExist && showTable && <CorrosionTable />}
      {showTable && <Disclaimer />}
    </div>
  );
}

export default CorrosionClusteringApp;
